<template>
  <v-col class="px-0 py-0">
    <v-data-table
      style="margin-top: 32px"
      :headers="headers"
      :items="routes"
      :loading="loading"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      no-data-text="Поки що ви не додали жодного маршруту"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <v-row no-gutters justify="center"></v-row>
        <span class="tableItem">{{ item.id }}</span>
      </template>
      <template v-slot:[`item.route`]="{ item }">
        <span class="tableItem"
          >{{
            item?.departure?.translations.find(
              (translate) => translate.lang == "ua"
            ).name
          }}
          -
          {{
            item?.destination?.translations.find(
              (translate) => translate.lang == "ua"
            ).name
          }}</span
        >
      </template>
      <template v-slot:[`item.from`]="{ item }">
        <span class="tableItem">{{
          item?.departure?.country?.translations.find(
            (translate) => translate.lang == "en"
          ).name
        }}</span>
      </template>
      <template v-slot:[`item.to`]="{ item }">
        <span class="tableItem">
          {{
            item?.destination?.country?.translations.find(
              (translate) => translate.lang == "en"
            ).name
          }}</span
        >
      </template>
      <template v-slot:[`item.is_popular`]="{ item }">
        <div style="width: 140px">
          <v-row no-gutters align="center" justify="center">
            <v-switch inset v-model="item.is_popular" />
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-menu offset-y rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="statusBox statusName"
              v-bind="attrs"
              v-on="on"
              :style="
                item.status == 'Active'
                  ? 'color: #149e51;'
                  : 'color: #DF3A3A; background:rgba(223, 58, 58, 0.10)'
              "
            >
              {{ item.status == "Active" ? "Активний" : "Деактивовано" }}
              <v-icon
                size="18px"
                :color="item.status == 'Active' ? '#149E51' : '#DF3A3A'"
                >mdi-menu-down</v-icon
              >
            </div>
          </template>
          <div
            style="
              border-radius: 9px;
              padding: 8px 16px;
              display: grid;
              place-items: center;
              background: #fff;
              width: 100%;
            "
            class="statusName"
            :style="
              item.status !== 'Active' ? 'color: #149E51' : 'color: #DF3A3A'
            "
            @click="changeRouteStatus(item)"
          >
            {{ item.status !== "Active" ? "Активний" : "Деактивовано" }}
          </div>
        </v-menu>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters align="center" justify="end">
          <v-tooltip bottom v-if="$user_role == 'admin'">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="iconBackground"
                style="margin-right: 12px"
                @click="
                  $router.push({ name: 'editRoute', params: { id: item.id } })
                "
              >
                <div
                  class="editIcon"
                  v-if="!item.is_new && !item.is_edit"
                  v-bind="attrs"
                  v-on="on"
                />
              </div>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="iconBackground">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="#828282"
                  class="pointer"
                  @click="
                    (symlinks = item.symlinks), (showDetailSymlinkModal = true)
                  "
                  >mdi-dots-vertical</v-icon
                >
              </div>
            </template>
            <span>Деталі</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>
    <detail-route-symlink-modal
      :visible="showDetailSymlinkModal"
      :symlinks="symlinks"
      @close="showDetailSymlinkModal = false"
    />
  </v-col>
</template>

<script>
import detailRouteSymlinkModal from "./detailRouteSymlinkModal.vue";
import routesService from "../../../requests/Admin/routesService";
export default {
  components: { detailRouteSymlinkModal },
  data: () => ({
    sortBy: "",
    sortDesc: "",
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Маршрут", value: "route", align: "start", sortable: false },
      { text: "Звідки", value: "from", align: "start", sortable: false },
      {
        text: "Куди",
        value: "to",
        align: "center",
        sortable: false,
      },
      {
        text: "Статус",
        value: "status",
        align: "start",
        sortable: true,
      },
      // {
      //   text: "Популярний",
      //   value: "is_popular",
      //   align: "start",
      // },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    showDetailSymlinkModal: false,
    symlinks: [],
  }),
  props: {
    routes: {
      require: true,
    },
    loading: {
      require: false,
    },
  },
  methods: {
    async changeRouteStatus(item) {
      await routesService.changeRouteStatus(item.id).then((res) => {
        if (res.status == "Success") {
          this.$emit("getRoutes");
        }
      });
    },
  },
  watch: {
    sortBy: {
      handler() {
        this.$emit("sortBy", this.sortBy, this.sortDesc);
      },
    },
    sortDesc: {
      handler() {
        this.$emit("sortBy", this.sortBy, this.sortDesc);
      },
    },
  },
};
</script>

<style scoped>
.statusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
  cursor: pointer;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
</style>
<style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
</style>