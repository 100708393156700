<template>
  <v-dialog v-model="visibility" width="500px">
    <v-card style="padding: 20px">
      <v-row no-gutters align="center">
        <h2
          style="
            color: #085895;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          "
        >
          Похідні маршрути
        </h2>
        <v-row no-gutters justify="end">
          <v-icon
            size="30px"
            color="#1b1b1b"
            class="pointer"
            @click="$emit('close')"
            >mdi-close</v-icon
          >
        </v-row>
      </v-row>
      <v-data-table
        style="margin-top: 12px"
        :headers="headers"
        :items="symlinksList"
        hide-default-footer
        hide-default-header
        :items-per-page="100"
        background-color="red"
        no-data-text="Поки що ви не додали жодного саймлінку"
      >
        <template v-slot:header="{ props }">
          <th
            @click="
              !head.sortable && head.sortable !== false
                ? (sortBy = head.value)((sortDesc = !sortDesc))
                : ''
            "
            v-for="head in props.headers"
            :key="head.id"
            style="padding: 5px"
          >
            <v-row
              no-gutters
              align="center"
              :justify="head.align ? head.align : 'start'"
              ><output
                style="
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0.1em;
                  color: #1b1b1b;
                  cursor: pointer;
                  user-select: none;
                "
                >{{ head.text }}
                <v-icon
                  v-if="head.sortable !== false"
                  size="25px"
                  :color="head.value == sortBy ? 'black' : ''"
                  >mdi-menu-swap</v-icon
                >
              </output>
            </v-row>
          </th>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-row no-gutters justify="center">
            <span class="tableItem">{{ item.id }}</span>
          </v-row>
        </template>
        <template v-slot:[`item.route`]="{ item }">
          <span class="tableItem"
            >{{ item?.departure?.translations?.[0]?.name }} -
            {{ item?.destination?.translations?.[0]?.name }}</span
          >
        </template>
        <template v-slot:[`item.is_popular`]="{ item }">
          <v-row no-gutters justify="center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="popularBox popularName"
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    item.is_popular == false
                      ? 'color: #CA0000;'
                      : 'color: #149e51;'
                  "
                >
                  {{ item.is_popular == false ? "Неактивний" : "Aктивний" }}
                  <v-icon size="18px" color="#1B1B1B">mdi-menu-down</v-icon>
                </div>
              </template>
              <div
                style="
                  border-radius: 5px;
                  border: 1px solid rgba(181, 181, 181, 0.3);
                  background: #fcfcfc;
                  padding: 12px;
                  display: grid;
                  place-items: center;
                  width: 100%;
                "
                class="popularName"
                :style="
                  item.is_popular == false
                    ? 'color: #149e51;'
                    : 'color: #CA0000;'
                "
                @click="createPopularRoute(item.id)"
              >
                {{ item.is_popular == false ? "Активний" : "Неактивний" }}
              </div>
            </v-menu>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import routesService from "../../../requests/Admin/routesService";
export default {
  mixins: [modalMixin],
  data: () => ({
    sortBy: "",
    sortDesc: "",
    headers: [
      {
        text: "№",
        align: "center",
        value: "id",
        sortable: false,
      },
      { text: "Маршрут", value: "route", align: "start", sortable: false },
      {
        text: "Популярний",
        value: "is_popular",
        align: "center",
        sortable: false,
      },
    ],
    symlinksList: [],
  }),
  props: {
    symlinks: {
      require: true,
    },
  },
  mounted() {
    this.symlinksList = this.symlinks;
  },
  methods: {
    async createPopularRoute(symlink_id) {
      let form = new FormData();
      form.append("symlink_id", symlink_id);
      await routesService.createPopularRoute(form).then((res) => {
        if (res.status == "Success") {
          let index = this.symlinksList.findIndex(
            (symlink) => symlink.id == symlink_id
          );
          this.symlinksList[index].is_popular = !this.symlinksList[index].is_popular;
        }
      });
    },
  },
  watch: {
    symlinks: {
      deep: true,
      handler() {
        this.symlinksList = this.symlinks;
      },
    },
  },
};
</script>

<style scoped>
.popularBox {
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  background: #fcfcfc;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.popularName {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.4px;
  width: max-content;
  user-select: none;
  cursor: pointer;
}
</style>