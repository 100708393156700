var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('v-card',{staticStyle:{"padding":"20px"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('h2',{staticStyle:{"color":"#085895","font-family":"Roboto","font-size":"24px","font-style":"normal","font-weight":"500","line-height":"24px"}},[_vm._v(" Похідні маршрути ")]),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-icon',{staticClass:"pointer",attrs:{"size":"30px","color":"#1b1b1b"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticStyle:{"margin-top":"12px"},attrs:{"headers":_vm.headers,"items":_vm.symlinksList,"hide-default-footer":"","hide-default-header":"","items-per-page":100,"background-color":"red","no-data-text":"Поки що ви не додали жодного саймлінку"},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(head){return _c('th',{key:head.id,staticStyle:{"padding":"5px"},on:{"click":function($event){!head.sortable && head.sortable !== false
              ? (_vm.sortBy = head.value)((_vm.sortDesc = !_vm.sortDesc))
              : ''}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":head.align ? head.align : 'start'}},[_c('output',{staticStyle:{"font-weight":"500","font-size":"16px","line-height":"22px","letter-spacing":"0.1em","color":"#1b1b1b","cursor":"pointer","user-select":"none"}},[_vm._v(_vm._s(head.text)+" "),(head.sortable !== false)?_c('v-icon',{attrs:{"size":"25px","color":head.value == _vm.sortBy ? 'black' : ''}},[_vm._v("mdi-menu-swap")]):_vm._e()],1)])],1)})}},{key:`item.id`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item.id))])])]}},{key:`item.route`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item?.departure?.translations?.[0]?.name)+" - "+_vm._s(item?.destination?.translations?.[0]?.name))])]}},{key:`item.is_popular`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"popularBox popularName",style:(item.is_popular == false
                    ? 'color: #CA0000;'
                    : 'color: #149e51;')},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.is_popular == false ? "Неактивний" : "Aктивний")+" "),_c('v-icon',{attrs:{"size":"18px","color":"#1B1B1B"}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('div',{staticClass:"popularName",staticStyle:{"border-radius":"5px","border":"1px solid rgba(181, 181, 181, 0.3)","background":"#fcfcfc","padding":"12px","display":"grid","place-items":"center","width":"100%"},style:(item.is_popular == false
                  ? 'color: #149e51;'
                  : 'color: #CA0000;'),on:{"click":function($event){return _vm.createPopularRoute(item.id)}}},[_vm._v(" "+_vm._s(item.is_popular == false ? "Активний" : "Неактивний")+" ")])])],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }